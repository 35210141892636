<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="common-body-720-90 flexCenter dflex error-page-body">
        <div>
          <img src="/img/tipsIcon.png" width="48" height="48" />
        </div>
        <div class="error-page-title">認証に失敗しました</div>
        <div>
          設定をご確認ください。設定の確認を行うため、最初の画面ページからもう一度ご登録してください。
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import { chevronBackOutline, homeOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'Error',
  data() {
    return {
      chevronBackOutline,
      homeOutline
    }
  },
  components: {
    IonContent,
    IonPage
  },
  methods: {}
})
</script>

<style scoped>
.error-page-body {
  min-height: 100%;
  flex-direction: column;
}
.error-page-title {
  font-size: 18px;
  color: #fff;
  margin: 14px 0 10px 0;
}
</style>
